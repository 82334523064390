/* Package imports */
import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

/* Utility imports */
import { GlobalValues } from "../constants/global";

/* Import layouts/containers */
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";

/* Import root component */
import Root from "./Root";

/* Lazy load all views */
// Error views
const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));
// Auth views
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
// Dashboard
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
// Organization
const ManageOrganization = React.lazy(() => import("../pages/organization/ManageOrganization"));
const ListOrganization = React.lazy(() => import("../pages/organization/ListOrganization"));
// Service
const ManageService = React.lazy(() => import("../pages/service/ManageService"));
const ListService = React.lazy(() => import("../pages/service/ListService"));
// Employee Category
const ManageEmployeeCategory = React.lazy(() => import("../pages/employeeCategory/ManageEmployeeCategory"));
const ListEmployeeCategory = React.lazy(() => import("../pages/employeeCategory/ListEmployeeCategory"));
// Checklist Category
const ManageChecklistCategory = React.lazy(() => import("../pages/checklistCategory/ManageChecklistCategory"));
const ListChecklistCategory = React.lazy(() => import("../pages/checklistCategory/ListChecklistCategory"));
// Checklist Item
const ManageChecklistItem = React.lazy(() => import("../pages/checklistItem/ManageChecklistItem"));
const ListChecklistItem = React.lazy(() => import("../pages/checklistItem/ListChecklistItem"));
// Project
const ManageProject = React.lazy(() => import("../pages/project/ManageProject"));
const ListProject = React.lazy(() => import("../pages/project/ListProject"));
// Project Zone
const ListProjectZoneManage = React.lazy(() => import("../pages/project/projectZone/ListProjectZoneManage"));
const ManageProjectZone = React.lazy(() => import("../pages/project/projectZone/ManageProjectZone"));
const ZonePdf = React.lazy(() => import("../pages/pdfExport/ZonePdf"));
// Project Organization
const ListProjectOrganizationManage = React.lazy(() => import("../pages/project/projectOrganization/ListProjectOrganizationManage"));
const ManageProjectOrganization = React.lazy(() => import("../pages/project/projectOrganization/ManageProjectOrganization"));
// Project Organization Service
const ManageProjectOrganizationService = React.lazy(() => import("../pages/project/projectOrganization/projectOrganizationService/ManageProjectOrganizationService"));
const ListProjectOrganizationServiceManage = React.lazy(() => import("../pages/project/projectOrganization/projectOrganizationService/ListProjectOrganizationServiceManage"));
// Project Organization Service Zone
const ManageProjectOrganizationServiceZone = React.lazy(() =>
    import("../pages/project/projectOrganization/projectOrganizationService/projectOrganizationServiceZone/ManageProjectOrganizationServiceZone")
);
const ListProjectOrganizationServiceZoneManage = React.lazy(() =>
    import("../pages/project/projectOrganization/projectOrganizationService/projectOrganizationServiceZone/ListProjectOrganizationServiceZoneManage")
);
// Project Organization Service Zone Checklist
const ManageProjectOrganizationServiceZoneChecklist = React.lazy(() =>
    import(
        "../pages/project/projectOrganization/projectOrganizationService/projectOrganizationServiceZone/projectOrganizationServiceZoneChecklist/ManageProjectOrganizationServiceZoneChecklist"
    )
);
// Project Management
const ManageProjectManagement = React.lazy(() => import("../pages/project/projectManagement/ManageProjectManagement"));
// User
const ManageUser = React.lazy(() => import("../pages/user/ManageUser"));
const ListUser = React.lazy(() => import("../pages/user/ListUser"));
const ListOrganizationUserManage = React.lazy(() => import("../pages/organization/organizationUser/ListOrganizationUserManage"));
const ManageOrganizationUser = React.lazy(() => import("../pages/organization/organizationUser/ManageOrganizationUser"));
// User Employee Category
const ManageUserEmployeeCategory = React.lazy(() => import("../pages/user/employeeCategory/ManageUserEmployeeCategory"));
// User Supervisor Project
const ListSupervisorProjectManage = React.lazy(() => import("../pages/user/userProject/ListSupervisorProjectManage"));
const ManageSupervisorProject = React.lazy(() => import("../pages/user/userProject/ManageSupervisorProject"));
// User Employee Project
const ListEmployeeProjectManage = React.lazy(() => import("../pages/user/userProject/ListEmployeeProjectManage"));
const ManageEmployeeProject = React.lazy(() => import("../pages/user/userProject/ManageEmployeeProject"));
// Project Manager
const ProjectManagerListProject = React.lazy(() => import("../pages/projectManager/ProjectManagerListProject"));
const ProjectManagerListProjectMaintenance = React.lazy(() => import("../pages/projectManager/projectMaintenanceReport/ProjectManagerListProjectMaintenance"));
const ListProjectMaintenanceLog = React.lazy(() => import("../pages/projectManager/projectMaintenanceReport/ListProjectMaintenanceLog"));
const ManageProjectMaintenanceReport = React.lazy(() => import("../pages/projectManager/projectMaintenanceReport/ManageProjectMaintenanceReport"));
const ManageProjectMaintenanceReportStatus = React.lazy(() => import("../pages/projectManager/projectMaintenanceReport/ManageProjectMaintenanceReportStatus"));
const PrMgrListProjectOrganizationManage = React.lazy(() => import("../pages/projectManager/organization/PrMgrListProjectOrganizationManage"));
const PrMgrListProjectOrganizationRequestManage = React.lazy(() => import("../pages/projectManager/organization/request/PrMgrListProjectOrganizationRequestManage"));
const ManagePrMgrProjectOrganizationRequest = React.lazy(() => import("../pages/projectManager/organization/request/ManagePrMgrProjectOrganizationRequest"));
const PrMgrListProjectScanManage = React.lazy(() => import("../pages/projectManager/scan/PrMgrListProjectScanManage"));
const PrMgrListProjectMissedScanManage = React.lazy(() => import("../pages/projectManager/scan/PrMgrListProjectMissedScanManage"));
const ManagePrMgrProjectScan = React.lazy(() => import("../pages/projectManager/scan/ManagePrMgrProjectScan"));
const PrMgrListProjectTicketManage = React.lazy(() => import("../pages/projectManager/ticket/PrMgrListProjectTicketManage"));
const ManagePrMgrProjectTicket = React.lazy(() => import("../pages/projectManager/ticket/ManagePrMgrProjectTicket"));
const PrMgrListProjectAttendanceManage = React.lazy(() => import("../pages/projectManager/attendance/PrMgrListProjectAttendanceManage"));
// Reporting
const ReportingListProject = React.lazy(() => import("../pages/reporting/ReportingListProject"));
const ScanManagementReportManage = React.lazy(() => import("../pages/reporting/scan/ScanManagementReportManage"));
const ScanExportManage = React.lazy(() => import("../pages/reporting/scanExport/ScanExportManage"));
const AttendanceReportManage = React.lazy(() => import("../pages/reporting/attendance/AttendanceReportManage"));
const ScanTimeComparisonReportManage = React.lazy(() => import("../pages/reporting/scanTimeComparison/ScanTimeComparisonReportManage"));
// Service Provider Type
const ManageServiceProviderType = React.lazy(() => import("../pages/serviceProviderType/ManageServiceProviderType"));
const ListServiceProviderType = React.lazy(() => import("../pages/serviceProviderType/ListServiceProviderType"));

//Asset Type
const ManageAssetType = React.lazy(() => import("../pages/assetType/ManageAssetType"));
const ListAssetType = React.lazy(() => import("../pages/assetType/ListAssetType"));

//Asset
const ListAssets = React.lazy(() => import("../pages/projectManager/assets/ManageListAccessories"));
const ManageAsset = React.lazy(() => import("../pages/projectManager/assets/ManageAccessory"));

//Asset Service Type
const ManageAssetServiceType = React.lazy(() => import("../pages/assetServiceType/ManageAssetServiceType"));
const ListAssetServiceType = React.lazy(() => import("../pages/assetServiceType/ListAssetServiceType"));

//Asset Service Provider
const ManageAssetServiceProvider = React.lazy(() => import("../pages/assetServiceType/assetServiceProvider/ManageAssetServiceProvider"));
const ListAssetServiceProvider = React.lazy(() => import("../pages/assetServiceType/assetServiceProvider/ListAssetServiceProvider"));

//Asset Service Provider Contact
const ManageAssetServiceProviderContact = React.lazy(() => import("../pages/assetServiceType/assetServiceProvider/assetServiceProviderContact/ManageAssetServiceProviderContact"));
const ListAssetServiceProviderContact = React.lazy(() => import("../pages/assetServiceType/assetServiceProvider/assetServiceProviderContact/ListAssetServiceProviderContact"));

//Asset Service Provider Feedback
const ManageAssetServiceProviderFeedback = React.lazy(() =>
    import("../pages/assetServiceType/assetServiceProvider/assetServiceProviderFeedback/ManageAssetServiceProviderFeedback")
);
const ListAssetServiceProviderFeedback = React.lazy(() => import("../pages/assetServiceType/assetServiceProvider/assetServiceProviderFeedback/ListAssetServiceProviderFeedback"));

//Asset Maintenance Log
const ManageAssetMaintenanceLog = React.lazy(() => import("../pages/assetMaintenanceLog/ManageAssetMaintenanceLog"));
const ListAssetMaintenanceLog = React.lazy(() => import("../pages/assetMaintenanceLog/ListAssetMaintenanceLog"));

//Asset Notification
const ManageAssetNotification = React.lazy(() => import("../pages/assetMaintenanceNotification/ManageAssetMaintenanceNotification"));
const ListAssetNotifications = React.lazy(() => import("../pages/assetMaintenanceNotification/ListAssetMaintenanceNotifications"));

//Asset AMC
const ListAmc = React.lazy(() => import("../pages/projectManager/assets/amc/ManageListAmc"));
const ManageAmc = React.lazy(() => import("../pages/projectManager/assets/amc/ManageAmc"));

//Asset Groups
const ListAssetGroups = React.lazy(() => import("../pages/projectManager/assetGroups/ManageListAssetGroups"));
const ManageAssetGroups = React.lazy(() => import("../pages/projectManager/assetGroups/ManageAssetGroups"));

//Asset Groups AMC
const ListAmcGroups = React.lazy(() => import("../pages/projectManager/assetGroups/amc/ManageListAmc"));
const ManageAmcGroups = React.lazy(() => import("../pages/projectManager/assetGroups/amc/ManageAmc"));

// Asset Groups Maintenance
const ListMaintenanceGroups = React.lazy(() => import("../pages/assetGroupMaintenanceLog/ListAssetMaintenanceLog"));
const ManageMaintenanceGroups = React.lazy(() => import("../pages/assetGroupMaintenanceLog/ManageAssetMaintenanceLog"));

//DLP
const DLPList = React.lazy(() => import("../pages/projectManager/DlpList"));

//AMC
const AMCList = React.lazy(() => import("../pages/projectManager/AmcList"));

/* Define loading component (fallback to suspenseful loading) */
const loading = () => <div className=""></div>;

/* Utility method for loading component */
const LoadComponent = ({ component: Component }) => {
    return (
        <Suspense fallback={loading()}>
            <Component />
        </Suspense>
    );
};

/* Declare all app routes */
const AllRoutes = () => {
    let Layout = VerticalLayout;

    return useRoutes([
        /* Root component which navigates to the required URL */
        { path: "/", element: <Root /> },
        {
            /* Public route */
            path: "/",
            element: <DefaultLayout />,
            children: [
                {
                    path: "account",
                    children: [
                        { path: "login", element: <LoadComponent component={Login} /> },
                        { path: "logout", element: <LoadComponent component={Logout} /> }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute component={Layout} />,
            children: [
                {
                    path: "dashboard",
                    children: [
                        {
                            path: "home",
                            element: <LoadComponent component={Dashboard} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.superAdministratorRole.id]} component={Layout} />,
            children: [
                {
                    path: "organization",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListOrganization} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageOrganization} />
                        },
                        {
                            path: "user/list",
                            element: <LoadComponent component={ListOrganizationUserManage} />
                        },
                        {
                            path: "user/manage",
                            element: <LoadComponent component={ManageOrganizationUser} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: (
                <PrivateRoute
                    roles={[GlobalValues.superAdministratorRole.id, GlobalValues.organizationAdministratorRole.id, GlobalValues.customerAdministratorRole.id]}
                    component={Layout}
                />
            ),
            children: [
                {
                    path: "user",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListUser} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageUser} />
                        },
                        {
                            path: "supervisor/project/list",
                            element: <LoadComponent component={ListSupervisorProjectManage} />
                        },
                        {
                            path: "supervisor/project/manage",
                            element: <LoadComponent component={ManageSupervisorProject} />
                        },
                        {
                            path: "employee/project/list",
                            element: <LoadComponent component={ListEmployeeProjectManage} />
                        },
                        {
                            path: "employee/project/manage",
                            element: <LoadComponent component={ManageEmployeeProject} />
                        },
                        {
                            path: "employeecategory/manage",
                            element: <LoadComponent component={ManageUserEmployeeCategory} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.superAdministratorRole.id]} component={Layout} />,
            children: [
                {
                    path: "service",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListService} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageService} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.organizationAdministratorRole.id]} component={Layout} />,
            children: [
                {
                    path: "employeecategory",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListEmployeeCategory} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageEmployeeCategory} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.superAdministratorRole.id]} component={Layout} />,
            children: [
                {
                    path: "checklistcategory",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListChecklistCategory} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageChecklistCategory} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.superAdministratorRole.id]} component={Layout} />,
            children: [
                {
                    path: "checklistitem",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListChecklistItem} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageChecklistItem} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.superAdministratorRole.id]} component={Layout} />,
            children: [
                {
                    path: "project",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListProject} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageProject} />
                        },
                        {
                            path: "zone/list",
                            element: <LoadComponent component={ListProjectZoneManage} />
                        },
                        {
                            path: "zone/manage",
                            element: <LoadComponent component={ManageProjectZone} />
                        },
                        {
                            path: "zone/export",
                            element: <LoadComponent component={ZonePdf} />
                        },
                        {
                            path: "organization/list",
                            element: <LoadComponent component={ListProjectOrganizationManage} />
                        },
                        {
                            path: "organization/manage",
                            element: <LoadComponent component={ManageProjectOrganization} />
                        },
                        {
                            path: "organization/service/list",
                            element: <LoadComponent component={ListProjectOrganizationServiceManage} />
                        },
                        {
                            path: "organization/service/manage",
                            element: <LoadComponent component={ManageProjectOrganizationService} />
                        },
                        {
                            path: "organization/service/zone/list",
                            element: <LoadComponent component={ListProjectOrganizationServiceZoneManage} />
                        },
                        {
                            path: "organization/service/zone/manage",
                            element: <LoadComponent component={ManageProjectOrganizationServiceZone} />
                        },
                        {
                            path: "organization/service/zone/checklistitem/manage",
                            element: <LoadComponent component={ManageProjectOrganizationServiceZoneChecklist} />
                        },
                        {
                            path: "management/manage",
                            element: <LoadComponent component={ManageProjectManagement} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.projectManagerRole.id]} component={Layout} />,
            children: [
                {
                    path: "projectmanager",
                    children: [
                        {
                            path: "project/list",
                            element: <LoadComponent component={ProjectManagerListProject} />
                        },
                        {
                            path: "asset-projects/list",
                            element: <LoadComponent component={ProjectManagerListProject} />
                        },
                        {
                            path: "project-maintenance-report/projects",
                            element: <LoadComponent component={ProjectManagerListProjectMaintenance} /> 
                        },
                        {
                            path: "project-maintenance-report/list",
                            element: <LoadComponent component={ListProjectMaintenanceLog} /> 
                        },
                        {
                            path: "project-maintenance-report/manage",
                            element: <LoadComponent component={ManageProjectMaintenanceReport} />
                        },
                        {
                            path: "project-maintenance-report/status",
                            element: <LoadComponent component={ManageProjectMaintenanceReportStatus} /> //!hi
                        },
                        {
                            path: "asset-projects/assets/list",
                            element: <LoadComponent component={ListAssets} />
                        },
                        {
                            path: "asset-projects/asset-groups/list",
                            element: <LoadComponent component={ListAssetGroups} />
                        },
                        {
                            path: "asset-projects/asset-groups/manage",
                            element: <LoadComponent component={ManageAssetGroups} />
                        },
                        {
                            path: "asset-projects/asset-groups/amc/list",
                            element: <LoadComponent component={ListAmcGroups} />
                        },
                        {
                            path: "asset-projects/asset-groups/amc/manage",
                            element: <LoadComponent component={ManageAmcGroups} />
                        },
                        {
                            path: "asset-projects/asset-groups/maintenance/list",
                            element: <LoadComponent component={ListMaintenanceGroups} />
                        },
                        {
                            path: "asset-projects/asset-groups/maintenance/manage", //!bye
                            element: <LoadComponent component={ManageMaintenanceGroups} />
                        },
                        {
                            path: "asset-projects/assets/manage",
                            element: <LoadComponent component={ManageAsset} />
                        },
                        {
                            path: "project/organization/list",
                            element: <LoadComponent component={PrMgrListProjectOrganizationManage} />
                        },
                        {
                            path: "project/organization/request/list",
                            element: <LoadComponent component={PrMgrListProjectOrganizationRequestManage} />
                        },
                        {
                            path: "project/organization/request/manage",
                            element: <LoadComponent component={ManagePrMgrProjectOrganizationRequest} />
                        },
                        {
                            path: "project/scan/list",
                            element: <LoadComponent component={PrMgrListProjectScanManage} />
                        },
                        {
                            path: "project/missed-scan/list",
                            element: <LoadComponent component={PrMgrListProjectMissedScanManage} />
                        },
                        {
                            path: "project/scan/manage",
                            element: <LoadComponent component={ManagePrMgrProjectScan} />
                        },
                        {
                            path: "project/ticket/list",
                            element: <LoadComponent component={PrMgrListProjectTicketManage} />
                        },
                        {
                            path: "project/ticket/manage",
                            element: <LoadComponent component={ManagePrMgrProjectTicket} />
                        },
                        {
                            path: "project/attendance/list",
                            element: <LoadComponent component={PrMgrListProjectAttendanceManage} />
                        },
                        {
                            path: "asset-projects/assets/amc/list",
                            element: <LoadComponent component={ListAmc} />
                        },
                        {
                            path: "asset-projects/assets/amc/manage",
                            element: <LoadComponent component={ManageAmc} />
                        }
                    ]
                },
                {
                    path: "maintenance",
                    children: [
                        {
                            path: "asset-alc/list",
                            element: <LoadComponent component={AMCList} />
                        },
                        {
                            path: "asset-dlp/list",
                            element: <LoadComponent component={DLPList} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.projectManagerRole.id]} component={Layout} />,
            children: [
                {
                    path: "reporting",
                    children: [
                        {
                            path: "project/list",
                            element: <LoadComponent component={ReportingListProject} />
                        },
                        {
                            path: "project/scan/report",
                            element: <LoadComponent component={ScanManagementReportManage} />
                        },
                        {
                            path: "project/scan/export",
                            element: <LoadComponent component={ScanExportManage} />
                        },
                        {
                            path: "project/attendance/report",
                            element: <LoadComponent component={AttendanceReportManage} />
                        },
                        {
                            path: "project/scan-time-comparison/report",
                            element: <LoadComponent component={ScanTimeComparisonReportManage} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.projectManagerRole.id]} component={Layout} />,
            children: [
                {
                    path: "serviceprovidertype",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListServiceProviderType} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageServiceProviderType} />
                        }
                    ]
                },

                {
                    path: "asset",
                    children: [
                        {
                            path: "asset-maintenance-log-list",
                            element: <LoadComponent component={ListAssetMaintenanceLog} />
                        },
                        {
                            path: "manage-asset-maintenance-log",
                            element: <LoadComponent component={ManageAssetMaintenanceLog} />
                        }
                    ]
                },
                {
                    path: "asset",
                    children: [
                        {
                            path: "asset-type-list",
                            element: <LoadComponent component={ListAssetType} />
                        },
                        {
                            path: "manage-asset-type",
                            element: <LoadComponent component={ManageAssetType} />
                        },
                        {
                            path: "asset-service-type-list",
                            element: <LoadComponent component={ListAssetServiceType} />
                        },
                        {
                            path: "manage-asset-service-type",
                            element: <LoadComponent component={ManageAssetServiceType} />
                        },
                        {
                            path: "asset-service-provider-list",
                            element: <LoadComponent component={ListAssetServiceProvider} />
                        },
                        {
                            path: "manage-asset-service-provider",
                            element: <LoadComponent component={ManageAssetServiceProvider} />
                        },
                        {
                            path: "asset-service-provider-contact-list",
                            element: <LoadComponent component={ListAssetServiceProviderContact} />
                        },
                        {
                            path: "manage-asset-service-provider-contact",
                            element: <LoadComponent component={ManageAssetServiceProviderContact} />
                        },
                        {
                            path: "asset-service-provider-feedback-list",
                            element: <LoadComponent component={ListAssetServiceProviderFeedback} />
                        },
                        {
                            path: "manage-asset-service-provider-feedback",
                            element: <LoadComponent component={ManageAssetServiceProviderFeedback} />
                        },
                        {
                            path: "asset-maintenance-notification-list",
                            element: <LoadComponent component={ListAssetNotifications} />
                        },
                        {
                            path: "manage-asset-maintenance-notification",
                            element: <LoadComponent component={ManageAssetNotification} />
                        }
                    ]
                }
            ]
        },
        /* Wildcard for page not found */
        { path: "*", element: <LoadComponent component={PageNotFound} /> }
    ]);
};

export { AllRoutes };
